// @ts-nocheck

import React, { FC, Suspense, lazy } from "react";
import { HashRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Setup from "./pages/Setup";
import Login from "./pages/Login";
import DeviceLinks from "./pages/DeviceLinks";
import ReceiptViewer from "./pages/ReceiptViewer";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/layout/header";
import TransactionGenerator from "./pages/TransactionGenerator";
import { GlobalStateProvider } from "./lib/GlobalState";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Preload = (importStatement) => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const PayzoneSimulator = Preload(() => import("./pages/PayzoneSimulator"));

PayzoneSimulator.preload();

const CounterSimulator = Preload(() => import("./pages/CounterSimulator"));

CounterSimulator.preload();

const SandboxSimulator = Preload(() => import("./pages/SandboxSimulator"));
SandboxSimulator.preload();

const App: FC = () => (
  <Router>
    <ToastContainer
      autoClose={3000}
      hideProgressBar={false}
      draggable={false}
      limit={2}
      role="alert"
      transition={Flip}
    />
    <GlobalStateProvider>
      <Header />
      <Switch>
        <Redirect from="/" to="/setup" exact={true} />
        <Route path="/setup" component={Setup} />
        <Route path="/login" component={Login} />
        <Route path="/devices" component={DeviceLinks} />
        <Route path="/transaction-generator" component={TransactionGenerator} />
        <Route path="/receipt-viewer" component={ReceiptViewer} />
        <Suspense fallback={<div>loading...</div>}>
          <Route path="/simulators/payzone" component={PayzoneSimulator} />
          <Route path="/simulators/journeysandbox" component={SandboxSimulator} />
          <Route path="/simulators/counterterminal" component={CounterSimulator} />
        </Suspense>
        <Route component={PageNotFound} />
      </Switch>
    </GlobalStateProvider>
  </Router>
);

export default App;
