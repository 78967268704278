import { Auth } from "aws-amplify";
import { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import useUserStatus from "../../lib/hooks/useUserStatus";
import { useGlobalState } from "../../lib/GlobalState";

const Header: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { setSandboxParams } = useGlobalState();

  const handleLogout = async () => {
    await Auth.signOut();
    setSandboxParams(null);
    history.push("/login");
  };

  const handleLogin = async () => {
    setSandboxParams(null);
    history.push("/login");
  };

  const userStatus = useUserStatus();
  const isLoggedIn = null !== userStatus;

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <img className="block w-auto h-8" src={Logo} alt="Post Office" />
            </div>
            <div
              className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
              onClick={() => history.push("/setup")}
            >
              <h3
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2  ${
                  location.pathname === "/setup" ? "border-pol-red border-b-2" : "border-gray-200 border-b"
                }`}
              >
                Device Simulator
              </h3>
            </div>
            <div
              className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
              onClick={() => history.push("/devices")}
            >
              <h3
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900   ${
                  location.pathname === "/devices" ? "border-pol-red border-b-2" : "border-gray-200 border-b"
                }`}
              >
                Your Devices
              </h3>
            </div>
            <div
              className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
              onClick={() => history.push("/receipt-viewer")}
            >
              <h3
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900   ${
                  location.pathname === "/receipt-viewer" ? "border-pol-red border-b-2" : "border-gray-200 border-b"
                }`}
              >
                Receipt Viewer
              </h3>
            </div>
            <div
              className="cursor-pointer sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
              onClick={() => history.push("/transaction-generator")}
            >
              <h3
                className={`inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900   ${
                  location.pathname === "/transaction-generator"
                    ? "border-pol-red border-b-2"
                    : "border-gray-200 border-b"
                }`}
              >
                Transaction Generator
              </h3>
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <div className="relative ml-3">
              {isLoggedIn && (
                <button
                  data-test="auth-flow-btn"
                  onClick={handleLogout}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Log Out
                </button>
              )}
              {!isLoggedIn && (
                <button
                  data-test="auth-flow-btn"
                  onClick={handleLogin}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Log In
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
